/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('documents', (http) => {
    const url = config.apiURL + 'documents/';
    return {
        check: (document, accepted = true) => {
            if (!document || !document._id) {
                return Promise.reject();
            }
            for(let i in document){
                if(['_id', 'comment'].indexOf(i) < 0){
                    delete document[i];
                }
            }
            let _url = url, params = [];
            _url += document._id + '/' + (accepted ? 'accept' : 'reject');
            return new Promise((resolve, reject) => {
                http.put(_url, document).then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        delete: (itemId, clientId = null) => {
            let _url = url + itemId + (clientId ? '/' + clientId : '');
            return new Promise((resolve, reject) => {
                http.delete(_url).then((res) => {
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        reset: (document) => {
            if (!document || !document._id) {
                return Promise.reject('NoData');
            }
            let _url = url, params = [];
            _url += document._id + '/reset';
            return new Promise((resolve, reject) => {
                http.put(_url, document).then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    reject(err);
                });
            });
        }
    };

});
